import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/docs/src/components/Guide.js"
import Button from 'components/Button.js';

export default ({components, ...props}) => <MDXTag name="wrapper" Layout={DefaultLayout} layoutProps={props} components={components}>


<MDXTag name="p" components={components}>{`HealthShare's official font is a modified version of `}<MDXTag name="em" components={components} parentName="p">{`FF Din Round;`}</MDXTag>{` a precise and modular typeface by `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.fontshop.com/families/ff-din-round"}}>{`FontFont.`}</MDXTag>{` It is a modern, rounded variant of `}<MDXTag name="em" components={components} parentName="p">{`FF DIN,`}</MDXTag>{` which itself is based on the `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://en.wikipedia.org/wiki/DIN_1451"}}>{`DIN 1451 technical drawing standard.`}</MDXTag>{` The reason for its creation rings true to HealthShare's brand when contrasted to typical health organisations:`}</MDXTag>
<MDXTag name="blockquote" components={components}>
<MDXTag name="p" components={components} parentName="blockquote">{`"Designed to bring a softness to FF DIN's simplicity and industrial sterility...with added round edges to give warmth to the somewhat sterile and mechanical original."`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`The font has been modified—and renamed `}<MDXTag name="em" components={components} parentName="p">{`HealthShare DIN`}</MDXTag>{`—to replace the default '@' glyph with an alternative from the character map.`}</MDXTag>
<Button
    download
    href="/HealthShare-DIN.zip"
    label="Download HealthShare DIN family"
/>
<MDXTag name="p" components={components}>{`For performance, familiarity and compliance with vendor interface guidelines, we use native fonts within products, balancing our branding with that of the platform.`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li">{`San Francisco UI`}</MDXTag>{` on iOS and macOS`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li">{`Roboto`}</MDXTag>{` on Android and Chrome OS`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li">{`Segoe UI`}</MDXTag>{` on Windows and Windows Phone`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li">{`Fira Sans`}</MDXTag>{` on Firefox OS`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li">{`Vera Sans`}</MDXTag>{` on Linux`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`To achieve this on the web, font-stacks should be defined at the top-level as follows:`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"className":"language-css","metaString":""}}>{`font-family:
    -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Fira Sans',
    Helvetica, sans-serif;
`}</MDXTag></MDXTag>
<Button
    download={true}
    href="/HealthShare-DIN-web.zip"
    label="Download HealthShare DIN for web use"
/>
<MDXTag name="h2" components={components}>{`Font sizes`}</MDXTag>
<MDXTag name="p" components={components}>{`As a company in the health industry making products for people with higher-than-average accessibility needs, we should go above and beyond to produce legible, accessible content.`}</MDXTag>
<MDXTag name="p" components={components}>{`Use the following rules when designing for screen and print:`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}></MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Software`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Print/screen ads`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Minimum font-size`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`16px`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`12pt`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`Line-height/leading`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`size `}{`×`}{` 1.5`}</MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`size `}{`×`}{` 1.5`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Font scales are important for maintaining harmony and maximising the hinting established by the font designer. Use the classic scale when designing for print or screen ads:`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"metaString":null}}>{`12, 14, 16, 18, 21, 24, 36, 48, 60, 72
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`When designing for software, base the scale on the master unit of the grid to connect type to the UI. For example, when `}<MDXTag name="inlineCode" components={components} parentName="p">{`gridUnit`}</MDXTag>{` = 12 and `}<MDXTag name="inlineCode" components={components} parentName="p">{`bodySize`}</MDXTag>{` = 16 (`}<MDXTag name="inlineCode" components={components} parentName="p">{`gridUnit`}</MDXTag>{` `}{`×`}{` 0.75), the scale would be:`}</MDXTag>
<MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{"metaString":null}}>{`16, 24, 30, 36, 48, 60, 72
`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Keep fonts, font-sizes, weights and forms of emphasis (colour, underline etc.) to a minimum regardless of product or medium.`}</MDXTag>
<MDXTag name="p" components={components}>{`Always use optical kerning when typesetting for print.`}</MDXTag>
<MDXTag name="h2" components={components}>{`License`}</MDXTag>
<MDXTag name="p" components={components}>{`HealthShare is licensed to use FF Din Round Pro on the web for up to `}<MDXTag name="strong" components={components} parentName="p">{`50,000 pageviews per month`}</MDXTag>{` and to be installed on `}<MDXTag name="strong" components={components} parentName="p">{`5 machines`}</MDXTag>{` for desktop usage. `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.fontshop.com/foundries/fontfont/eulas"}}>{`View the EULA.`}</MDXTag></MDXTag></MDXTag>

export const _frontmatter = {};

  